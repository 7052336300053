import React, { Component } from 'react';
import { List, ListItem, ListTitle } from "monday-ui-react-core";

export class PlaceholderList extends Component {
    static displayName = PlaceholderList.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            id: '',
            content: '',
        };

        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.handlePlaceholderClick = this.handlePlaceholderClick.bind(this);        
    }

    componentDidMount() {
    }

    handlePlaceholderClick(group, placeholder) {
        let result = placeholder.placeholder;
        if (!result)            
            result = group.dontUseGroup ? placeholder.name : group.name + '.' + placeholder.name;        
        let fullName = '{{' + result + '}}';
        this.props.onPlaceholderClick(fullName);
    }

    render() {
        return (
            <div>
                <h3>Placeholders</h3>
                <List>
                    {this.props.placeholders.map(group => (
                        <div key={group.name}>
                            <ListTitle>{group.name}</ListTitle>
                            {group.placeholders.map(placeholder => (
                                <ListItem className="app-spirit-placeholder-item" key={placeholder.name} onClick={() => this.handlePlaceholderClick(group, placeholder)}>
                                    {placeholder.name}
                                </ListItem>))}
                        </div>))}
                </List>
            </div>
        );
    }
}