import download from "downloadjs";

export async function initiateDownloadFromFetchResponse(response) {
    // Get the blob
    var blob = await response.blob();

    // Extract the filename
    var contentDisposition = response.headers.get('Content-Disposition');
    var filename = "default-filename"; // Default filename if not found in header
    if (contentDisposition) {
        var filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        var filenameStarMatch = contentDisposition.match(/filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

        if (filenameStarMatch && filenameStarMatch.length > 1) {
            // Decode the filename if it's URL-encoded (for filename*)
            filename = decodeURIComponent(filenameStarMatch[1].split('\'')[2]);
        } else if (filenameMatch && filenameMatch.length > 1) {
            // Simply use the filename (for filename)
            filename = filenameMatch[1].replace(/['"]/g, ''); // Remove any single or double quotes
        }
    }
    var mimeType = response.headers.get('Content-Type');
    //console.log(filename);
    //console.log(mimeType);
    download(blob, filename, mimeType);
}

export async function downloadCreatedDocument(documentContent) {
    try {
        // Fetch the file
        var response = await fetch('/document/Download?Id=' + documentContent.id + '&SessionToken=' + window.mondaySessionToken);

        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        initiateDownloadFromFetchResponse(response);
        //    // Create a link and download the file
        //    var link = document.createElement('a');
        //    link.href = window.URL.createObjectURL(blob);
        //    link.download = filename;
        //    document.body.appendChild(link);
        //    link.click();

        //    // Clean up
        //    window.URL.revokeObjectURL(link.href);
        //    document.body.removeChild(link);
    } catch (error) {
        console.error('Download failed:', error);
    }
}

export async function downloadExistingTemplate(template, sessionToken) {
    try {
        // Fetch the file
        var response = await fetch('/template/Download?Id=' + template.id + '&SessionToken=' + window.mondaySessionToken);

        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        initiateDownloadFromFetchResponse(response);
    } catch (error) {
        console.error('Download failed:', error);
    }
}

export async function downloadExistingTableDefinition(tableDefinition, sessionToken) {
    try {
        // Fetch the file
        var response = await fetch('/tabledefinition/Download?Id=' + tableDefinition.id + '&SessionToken=' + window.mondaySessionToken);

        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        initiateDownloadFromFetchResponse(response);
    } catch (error) {
        console.error('Download failed:', error);
    }
}