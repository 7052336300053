import React, { Component } from 'react';
import { Tab, TabList, TabPanel, TabPanels, TabsContext, Loader } from "monday-ui-react-core";
import { AlertBanner, AlertBannerText, AlertBannerLink } from "monday-ui-react-core";
import { GenerateDocument } from "./components/GenerateDocument";
import { Documents } from "./components/Documents";
import { Templates } from "./components/Templates";
import { AppSettings } from "./components/Settings";
import { BoardPrint } from "./components/BoardPrint";
import { ExcelExport } from "./components/ExcelExport";
import { Recipes } from "./components/Recipes";
import { ChartDefinitions } from "./components/ChartDefinitions";
import { TableDefinitions } from "./components/TableDefinitions";
import { Subscription } from "./components/Subscription";
import './custom.css';
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/tokens";
import { MondayContext } from './components/MondayContext';

// https://react.dev/learn/updating-arrays-in-state

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.isAlreadyLoaded = false;
        this.state = {
            mondayContext: undefined,
            appInfos: undefined,
            //dontShowWelcome: false,
            isAuthenticated: false,
            canRender: false,
            hideTabs: false,
            isBoardOwner: false,
            isAlertBannerClosed: false,
            columns: undefined,
            subItemsColumns: undefined,
            rightGenerate: false,
            rightRead: false,
        };

        const path = window.location.pathname;
        //this.isReportGenerator = true;
        this.isReportGenerator = path === '/reportgenerator';
        this.handleAssistantModeChanges = this.handleAssistantModeChanges.bind(this);
        this.handleAlertBannerCloseClick = this.handleAlertBannerCloseClick.bind(this);
        this.handleUpgradeClick = this.handleUpgradeClick.bind(this);

        //    window.onbeforeunload = function (event) {
        //        // https://stackoverflow.com/questions/2229942/how-to-block-users-from-closing-a-window-in-javascript
        //        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        //        console.log("beforeunload");
        //        event.preventDefault();
        //        event.returnValue = ""; // required in some browsers
        //        return "You have attempted to leave this page. Are you sure?"; //(event.returnValue = "");
        //    }
    }

    handleAssistantModeChanges(hideTabs, documentLimitReached) {
        this.setState({ hideTabs: hideTabs });
        if (documentLimitReached && this.state.appInfos) {
            var appInfos = { ...this.state.appInfos };
            appInfos.isDocumentLimitReached = true;
            this.setState({ appInfos: appInfos });
            //this.state.appInfos.isDocumentLimitReached = true;
        }
    }

    handleAlertBannerCloseClick(event) {
        //console.log('Close');        
        event.preventDefault();
        this.setState({ isAlertBannerClosed: true });
    }

    handleUpgradeClick(event) {
        event.preventDefault();
        // https://developer.monday.com/apps/docs/mondayexecute
        monday.execute('openPlanSelection', { isInPlanSelection: true });
    }

    async componentDidMount() {

        // This function is called twice -> prevent
        if (this.isAlreadyLoaded)
            return;
        this.isAlreadyLoaded = true;

        monday.get('context').then(context => {
            //console.log(context);
            var mondayContext = context.data;
            if (mondayContext) {
                mondayContext.connected = true;
                this.initApp(mondayContext);
            }
            else {  // Not in monday.com -> Only for testing                
                mondayContext = {
                    connected: false,
                    workspaceId: 3837413,
                    //boardId: 6811049418,      // julitec-test Invoices Mirror
                    boardId: 5214735932,    // julitec-gmbh Invoices
                    //boardId: 6719678521,    // julitec-gmbh Customer Test
                    //boardId: 1372719237,    // Tower Registration Form
                    //boardId: 1519735742,      // Tower Laura Projects Q2.24
                    theme: "light", //"dark", //"light",
                    user: {
                        currentLanguage: "de", //"en",
                        countryCode: "DE", //"US",
                        isViewOnly: false,
                        //id: 45408526      // Tower Johannes Geyer
                        id: 40830075    // Johannes Geyer (julitec)
                        //id: 62041960    // julitec-test
                    }
                }
                var towerAppInfos = {
                    isDocumentLimitReached: false,
                    isEnterprise: true,
                    isProfessional: true,
                    isStandard: true,
                    isInactive: false,
                    isFree: false,
                    hasEmailTemplates: true,
                    hasSharing: true,
                    templateSizeMB: 5.5,
                    isDebug: true
                }
                var columns = [{ id: 'files88', title: 'Dateispalte', type: 'file' },
                    { id: 'date', title: 'Datumsspalte', type: 'date', width: 150 },
                    { id: 'subtasks', title: 'Subtasks', type: 'subtasks' },
                    { id: 'numbers', title: 'Number', type: 'numbers', width: 180 },
                    { id: 'text', title: 'Text', type: 'text', width: 200 },
                    { id: 'location', title: 'Location', type: 'location' },
                    { id: 'timelne', title: 'Timeline', type: 'timeline' }];
                this.setMondayContext(mondayContext);
                window.towerAppInfos = towerAppInfos;
                this.setState({
                    appInfos: towerAppInfos,
                    canRender: true,
                    isAuthenticated: true,
                    isBoardOwner: true,
                    mondayContext: mondayContext,
                    columns: columns,
                    rightRead: true,
                    rightGenerate: true,
                });
            }

            //monday.get("itemIds").then(res => console.log(res));
            //monday.get("filter").then(res => console.log(res));

            //this.userLanguage = 'en';
            //this.mondayContext = window.mondayContext; -> Put this into "initApp()" after getting the mondayContext
            //if (this.mondayContext)
            //    this.userLanguage = this.mondayContext.user.currentLanguage;

            this.texts = {
                welcome: 'Welcome',
                generate: 'Generate Document',
                documents: 'Documents',
                templates: 'Templates',
                recipes: 'Recipes',
                subscription: 'Subscription',
                charts: 'Charts',
                tables: 'Tables',
                aboutus: 'About us'
            }

            if (this.isReportGenerator) {
                this.texts = {
                    welcome: 'Welcome',
                    generate: 'Generate Report',
                    documents: 'Reports',  // TODO: Button "New Report" auf Tab -> Geht zu "Generate Report"!
                    templates: 'Templates',
                    recipes: 'Recipes',
                    subscription: 'Subscription',
                    charts: 'Charts',
                    tables: 'Tables',
                    aboutus: 'About us'
                }                
            }

            //if (this.userLanguage === 'de') {
            //    this.texts = {
            //        welcome: 'Willkommen',
            //        generate: 'Neu erstellen',
            //        documents: 'Erzeugte Dokumente',
            //        templates: 'Vorlagen',
            //        recipes: 'Rezepte',
            //        subscription: 'Abonnement',
            //        aboutus: '\u00DCber uns'
            //    }
            //}
        });
    }

    initApp(mondayContext) {
        // Viewers can not use the API, so I cannot get informations about the board: UserUnauthorizedException
        if (mondayContext.user.isViewOnly) {
            this.setState({ mondayContext: mondayContext, canRender: true });
            this.setMondayContext(mondayContext);
            monday.get('sessionToken').then(settingsToken => {
                window.mondaySessionToken = settingsToken.data;
                this.setState({ isAuthenticated: true });
            });
            return;
        }

        if (!mondayContext.boardId) {  // Settings
            // mondayContext.instanceType === 'account_settings_view'
            this.setState({ mondayContext: mondayContext, canRender: true });
            this.setMondayContext(mondayContext);
            monday.get('sessionToken').then(settingsToken => {
                window.mondaySessionToken = settingsToken.data;
                this.setState({ isAuthenticated: true });
            });
            return;
        }

        const boardDataPromise = monday.api(`
query {
  complexity {
    query
    after
  }
  boards (ids: ${mondayContext.boardId}) { 
    id      
    name
    board_folder_id
    item_terminology
    columns {
        id
        title
        type
        width
        settings_str
    }
    owners {
      id
    }
    subscribers {
      id
    }
  }
  account {
    products {
      id,
      kind
    }
  }
        }`, { apiVersion: '2023-10' });
        const tokenPromise = monday.get('sessionToken');

        Promise.all([boardDataPromise, tokenPromise]).then(([boardData, token]) => {
            //console.log(boardData);
            var isOwner = mondayContext.user.isAdmin;
            if (!isOwner) {
                var userId = mondayContext.user.id;
                // Find user with userId in boardData.data.boards[0].owners
                var board = boardData.data.boards[0];
                // Check if the user is an owner using Array.prototype.some()            
                isOwner = board.owners.some(owner => owner.id === userId);
            }

            var products = boardData.data.account.products.map(product => product.kind).join(',');
            var columns = boardData.data.boards[0].columns;
            columns.filter(c => c.type === 'name').forEach(c => c.item_terminology = boardData.data.boards[0].item_terminology);

            window.mondaySessionToken = token.data;
            this.isBoardOwner = isOwner;
            this.setState({ isAuthenticated: true, isBoardOwner: isOwner, mondayContext: mondayContext, columns: columns });

            this.setMondayContext(mondayContext);
            this.loadMirrorColumnData(columns)
                .then(nextColumns => {
                    if (nextColumns !== this.state.columns) {
                        this.setState({ columns: nextColumns });
                    }                    
                })
                .catch(error => console.error('Error updating mirror columns:', error));
            this.loadSubItemsColumnData(columns);

            monday.listen("context", res => {
                //console.log('context changed');
                //console.log(res);
                var newContext = res.data;
                if (newContext) {
                    newContext.connected = true;
                    this.setMondayContext(newContext);
                    this.setState({ mondayContext: newContext });
                }
            });

            fetch('accountmanagement/AppInfos?UserId=' + mondayContext.user.id +
                '&AccountId=' + mondayContext.account.id +
                '&BoardId=' + mondayContext.boardId +
                '&Products=' + encodeURIComponent(products) +
                "&SessionToken=" + window.mondaySessionToken).then((response) => {
                    response.json().then((data) => {
                        //console.log(data);
                        let rightRead = this.hasRight(boardData, data.readRight);
                        let rightGenerate = !mondayContext.user.isViewOnly && this.hasRight(boardData, data.generateRight);
                        this.setState({ appInfos: data, canRender: true, rightRead: rightRead, rightGenerate: rightGenerate });
                        window.towerAppInfos = data;
                    });
                });
        }).catch(error => {
            console.error("Error in one of the promises", error);
        });
    }

    hasRight(boardData, rightValue) {
        // Owner:  Board Owners & Admins
        // Member: Board Members (no guests, even if they are board members)
        // Guest:  Board Members & Guests (no matter if guest is board member or not)
        // Viewer: Viewers (Read right only, not "Generate right") -> All Users

        if (this.mondayContext.user.isAdmin)
            return true;
        if (this.isBoardOwner)
            return true;
        if (rightValue === 'All')
            return true;
        if (this.mondayContext.user.isViewOnly)
            return rightValue === 'Viewer';
        if (this.mondayContext.user.isGuest)
            return rightValue === 'Guest' || rightValue === 'Viewer';
        if (rightValue === 'Member' || rightValue === 'Guest')
            if (boardData.data.boards[0].subscribers.some(subscriber => subscriber.id === this.mondayContext.user.id))
                return true;
        return false;
    }

    GetColumnQuery(boardId) {
        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardId}) { 
    id      
    name
    item_terminology
    columns {
        id
        title
        type
        width
        settings_str
    }
  }
}`;
    }

    loadSubItemsColumnData(columns) {
        var subItemsColumns = columns.filter(c => c.type === 'subtasks');

        if (subItemsColumns.length === 0)
            return;

        var subItemsColumn = subItemsColumns[0];

        var settings = this.getColumnSettings(subItemsColumn);
        if (!settings)
            return;
        //try {
        //    // Parse the settings_str JSON string
        //    settings = JSON.parse(subItemsColumn.settings_str);
        //} catch (error) {
        //    console.error("Error parsing settings string:", error);
        //    return;
        //}

        // Access the boardIds property
        var boardIds = settings.boardIds;
        if (!boardIds || boardIds.length === 0)
            return;

        var boardId = boardIds[0];

        monday.api(this.GetColumnQuery(boardId), { apiVersion: '2023-10' })
            .then(columnData => {
                var columns = columnData.data.boards[0].columns;
                this.setState({ subItemsColumns: columns });
                //console.log(columnData);
                // DONE: Load mirror columns for subItemsColumns
                this.loadMirrorColumnData(columns)
                    .then(nextColumns => {
                        if (nextColumns !== this.state.subItemsColumns) {
                            this.setState({ subItemsColumns: nextColumns });
                        }                        
                    })
                    .catch(error => console.error('Error updating mirror columns:', error));
            })
            .catch(error => {
                console.error("API call failed:", error);
            });
    }

    loadMirrorColumnData(columns) {
        return new Promise((resolve, reject) => {
            const mirrorColumns = columns.filter(c => c.type === 'mirror');            

            let allBoardIds = [];
            let allColumnIds = [];

            mirrorColumns.forEach(column => {
                const settingsObj = this.getColumnSettings(column);
                if (settingsObj.displayed_linked_columns) {
                    Object.entries(settingsObj.displayed_linked_columns).forEach(([id, value]) => {
                        if (!allBoardIds.includes(id)) {
                            allBoardIds.push(id);
                        }
                        //allColumnIds = allColumnIds.concat(value);
                        value.forEach(colId => {
                            if (!allColumnIds.includes(colId)) {
                                allColumnIds.push(colId);
                            }
                        });
                    });
                }
            });

            if (allBoardIds.length === 0 || allColumnIds.length === 0) {                
                resolve(columns); // Resolve with original columns if no updates are needed
                return;
            }

            monday.api(this.getMirrorColumnsQuery(allBoardIds, allColumnIds), { apiVersion: '2023-10' })
                .then(columnData => {
                    const nextColumns = columns.map(column => {
                        const settingsObj = this.getColumnSettings(column);
                        if (settingsObj.displayed_linked_columns) {
                            const [firstBoardId, firstValueArray] = Object.entries(settingsObj.displayed_linked_columns)[0];
                            if (firstValueArray.length > 0) {
                                const firstColumnId = firstValueArray[0];
                                const mirroredBoard = columnData.data.boards.find(b => b.id === firstBoardId);
                                const mirroredColumn = mirroredBoard ? mirroredBoard.columns.find(c => c.id === firstColumnId) : null;

                                if (mirroredColumn) {
                                    return {
                                        ...column,
                                        mirrorType: mirroredColumn.type,
                                        mirrorSettings: mirroredColumn.settings_str
                                    };
                                }
                            }
                        }
                        return column;
                    });
                    resolve(nextColumns);
                })
                .catch(error => {
                    console.error('Failed to fetch mirror column data:', error);
                    reject(error);
                });
        });
    }

    getColumnSettings(column) {
        //if (column.settings)
        //    return column.settings;
        if (!column.settings_str)
            return undefined;
        var settings;
        try {
            // Parse the settings_str JSON string
            settings = JSON.parse(column.settings_str);
            //column.settings = settings;  // DONE: Don't save it here!!! -> Blowing up the state and also the POST requests
        } catch (error) {
            console.error("Error parsing settings string:", error);
            return;
        }
        return settings;
    }

    getMirrorColumnsQuery(boardIds, columnIds) {
        // Convert boardIds array to a string with items separated by commas and wrapped in brackets
        const boardIdsStr = `[${boardIds.join(", ")}]`;

        // Convert columnIds array to a string with items quoted, separated by commas, and wrapped in brackets
        const columnIdsStr = `["${columnIds.join('", "')}"]`;

        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardIdsStr}) {
    id
    columns (ids: ${columnIdsStr}) {
      id
      type
      title
      settings_str
      width
    }
  }
}`;
    }

    setMondayContext(mondayContext) {
        window.mondayContext = mondayContext;
        this.mondayContext = mondayContext;
        //this.setState({ mondayContext: mondayContext });
        this.updateTheme(mondayContext.theme);
    }

    updateTheme(theme) {
        var themeName = theme + '-app-theme';
        document.getElementsByTagName("body")[0].className = themeName;
    }

    renderDocumentLimitReached() {
        if (!this.state.appInfos)
            return null;
        var showBanner = this.state.appInfos.isDocumentLimitReached || this.state.appInfos.isInactive || this.state.appInfos.isFree;
        if (!showBanner)
            return null;
        if (this.state.isAlertBannerClosed)
            return null;
        var text = "You reached your monthly document limit.";
        var backgroundColor = AlertBanner.backgroundColors.NEGATIVE;
        var isCloseHidden = true;
        if (this.state.appInfos.isInactive)
            text = "Your subscription is inactive. You can choose the Free plan to continue.";
        if (this.state.appInfos.isFree) {
            text = "You are on a free plan now. Upgrade to a paid plan to get more features.";
            backgroundColor = AlertBanner.backgroundColors.PRIMARY;
            isCloseHidden = false;
        }
        return (<AlertBanner
            bannerText={text}
            backgroundColor={backgroundColor}
            linkText="this is a CTA"
            isCloseHidden={isCloseHidden}
            onClose={this.handleAlertBannerCloseClick}
        >
            <AlertBannerText text={text} />
            <AlertBannerLink
                onClick={this.handleUpgradeClick}
                text="Upgrade now"
            />
        </AlertBanner>);
    }

    render() {
        if (!this.state.canRender)
            return (<Loader size={40} />); // TODO: Align center //(<span>Loading...</span>);
        if (!this.state.isAuthenticated)
            return (<span>Authenticating...</span>);

        const path = window.location.pathname;
        if (path === '/appsettings') {
            return (<AppSettings />);
        }
        if (path === '/boardprint') {
            return (<BoardPrint columns={this.state.columns} subItemsColumns={this.state.subItemsColumns} />);
        }
        if (path === '/itemprint') {
            return (<BoardPrint isItemPrint={true} columns={this.state.columns} subItemsColumns={this.state.subItemsColumns} />);
        }
        if (path === '/excelexport') {
            return (<ExcelExport columns={this.state.columns} subItemsColumns={this.state.subItemsColumns} />);
        }

        var tabListClassName = "";
        if (this.state.hideTabs)
            tabListClassName = "app-spirit-hidden";

        var imgFileName = '/DocExport.png';
        if (this.mondayContext.theme === 'dark')
            imgFileName = '/DocExport_Dark_Hacker.png';
        if (this.mondayContext.theme === 'hacker_theme')
            imgFileName = '/DocExport_Dark_Hacker.png';
        if (this.mondayContext.theme === 'black')
            imgFileName = '/DocExport_Night.png';
        if (this.isReportGenerator) {
            imgFileName = "/PDF Report Light.png";
            if (this.mondayContext.theme === 'dark')
                imgFileName = '/PDF Report Dark.png';
            if (this.mondayContext.theme === 'hacker_theme')
                imgFileName = '/PDF Report Dark.png';
            if (this.mondayContext.theme === 'black')
                imgFileName = '/PDF Report Night.png';
        }            

        let documentLimitReachedBanner = this.renderDocumentLimitReached();

        var isBoardOwner = this.state.isBoardOwner;
        var rightGenerate = this.state.rightGenerate;
        if (!this.state.rightRead)
            return (
                <p>I am sorry, but you don't have any rights in this app. Please contact your administrator.</p>
            );
        let activeTabId = undefined;
        if (!rightGenerate)
            activeTabId = 1;

        return (
            <MondayContext.Provider value={this.mondayContext}>
                {documentLimitReachedBanner}
                <img className="app-spirit-logo" src={imgFileName} alt="Logo" />
                <TabsContext activeTabId={activeTabId}>
                    <TabList className={tabListClassName}>
                        <Tab className={rightGenerate ? '' : "app-spirit-hidden"}>
                            {this.texts.generate}
                        </Tab>
                        <Tab>
                            {this.texts.documents}
                        </Tab>
                        <Tab className={rightGenerate ? '' : "app-spirit-hidden"}>
                            {this.texts.templates}
                        </Tab>
                        <Tab className={rightGenerate ? '' : "app-spirit-hidden"}>
                            {this.texts.recipes}
                        </Tab>
                        <Tab className={rightGenerate ? '' : "app-spirit-hidden"}>
                            {this.texts.tables}
                        </Tab>
                        <Tab className={rightGenerate ? '' : "app-spirit-hidden"}>
                            {this.texts.charts}
                        </Tab>
                        <Tab className={isBoardOwner ? '' : "app-spirit-hidden"}>
                            {this.texts.subscription}
                        </Tab>
                    </TabList>
                    <TabPanels className="app-spirit-tabpanels" renderOnlyActiveTab="true">
                        <TabPanel>
                            {rightGenerate && <GenerateDocument columns={this.state.columns} subItemsColumns={this.state.subItemsColumns} isReportGenerator={this.isReportGenerator} onAssistantModeChanges={this.handleAssistantModeChanges} />}
                        </TabPanel>
                        <TabPanel>
                            <Documents isBoardOwner={isBoardOwner} isReportGenerator={this.isReportGenerator} />
                        </TabPanel>
                        <TabPanel>
                            {rightGenerate && <Templates isBoardOwner={isBoardOwner} isReportGenerator={this.isReportGenerator} />}
                        </TabPanel>
                        <TabPanel>
                            {rightGenerate && <Recipes columns={this.state.columns} isBoardOwner={isBoardOwner} isReportGenerator={this.isReportGenerator} />}
                        </TabPanel>
                        <TabPanel>
                            {rightGenerate && <TableDefinitions columns={this.state.columns} subItemsColumns={this.state.subItemsColumns} isBoardOwner={isBoardOwner} isReportGenerator={this.isReportGenerator} />}
                        </TabPanel>
                        <TabPanel>
                            {rightGenerate && <ChartDefinitions isBoardOwner={isBoardOwner} isReportGenerator={this.isReportGenerator} />}
                        </TabPanel>
                        <TabPanel>
                            <Subscription isReportGenerator={this.isReportGenerator} />
                        </TabPanel>
                    </TabPanels>
                </TabsContext>
                <div id="tooltips-container"></div>
            </MondayContext.Provider>
        );
    }
}
