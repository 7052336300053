import React, { Component } from 'react';
import { Button, Link } from "monday-ui-react-core";
import { IconButton } from "monday-ui-react-core";
import { Delete } from "monday-ui-react-core/icons";
import { Download } from "monday-ui-react-core/icons";
import axios from 'axios';
import { Table, TableCell, TableBody, TableHeader, TableHeaderCell, TableRow } from "monday-ui-react-core";
import { TableDefinitionEdit } from './TableDefinitionEdit';
import { downloadExistingTableDefinition } from './fileDownloader';
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const TableEmptyState = () => <h1 style={{ textAlign: "center" }}>Empty State</h1>;

const TableErrorState = () => <h1 style={{ textAlign: "center" }}>Error State</h1>;

export class TableDefinitions extends Component {
    static displayName = TableDefinitions.name;

    constructor(props) {
        super(props);
        this.state = {
            tableDefinitions: [],
            createNewTableDefinition: false,
            loading: true,
            isBoardOwner: props.isBoardOwner
        };
        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.handleClickCreateNewTableDefinition = this.handleClickCreateNewTableDefinition.bind(this);
        this.handleTableEditClose = this.handleTableEditClose.bind(this);
        this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
        this.downloadTableDefinition = this.downloadTableDefinition.bind(this);
    }

    async downloadTableDefinition(tableDefinition) {
        await downloadExistingTableDefinition(tableDefinition, window.mondaySessionToken);
    }

    componentDidMount() {
        //console.log(this.context);
        if (!this.towerAppInfos)
            return;
        this.populateTableDefinitionData();
    }

    handleTableEditClose(table, isAlreadyExisting) {
        if (isAlreadyExisting) {
            monday.execute("notice", {
                message: 'This table definition already exists with name ' + table.name,
                type: "info", // or "error" (red), or "info" (blue)
                timeout: 6000,
            });
        }
        this.setState({ createNewTableDefinition: false });
        this.populateTableDefinitionData();
    }

    renderNewTable(tableDefinitions) {

        var columns = [{
            id: "download",
            title: "",
            width: 40,
            //loadingStateType: "circle"
        }, {
            id: "name",
            title: "Name",
            width: {
                min: 120,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "placeholder",
            title: "Placeholder",
            width: {
                min: 150,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "lastChangedOn",
            title: "Date",
            width: 160,
            //infoContent: "Info content for the status column",
            loadingStateType: "medium-text"
        }, {
            id: "delete",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }];

        var tableDataState = {
            isLoading: this.state.loading
        };

        return (
            <Table errorState={<TableErrorState />} emptyState={<TableEmptyState />} columns={columns} dataState={tableDataState}>
                <TableHeader>
                    {columns.map((headerCell, index) => <TableHeaderCell key={index} title={headerCell.title} />)}
                </TableHeader>
                <TableBody>
                    {tableDefinitions.map(rowItem => <TableRow key={rowItem.id}>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            <IconButton
                                ariaLabel="Download"
                                size={IconButton.sizes.SMALL}
                                icon={Download}
                                onClick={() => this.downloadTableDefinition(rowItem)}
                            />
                        </TableCell>
                        <TableCell>{rowItem.name}</TableCell>
                        <TableCell>{rowItem.placeholder}</TableCell>
                        <TableCell>{rowItem.lastChangedOn}</TableCell>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && (
                                <IconButton
                                    ariaLabel="Delete"
                                    size={IconButton.sizes.SMALL}
                                    icon={Delete}
                                    onClick={(e) => this.deleteTableDefinition(e, rowItem.id)}
                                />
                            )}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        );
    }

    async deleteTableDefinition(event, id) {
        console.log('Delete table definition ' + id);
        try {
            const response = await axios.delete('tabledefinition?Id=' + id + '&SessionToken=' + window.mondaySessionToken);
            console.log('Success:', response.data);
            await this.populateTableDefinitionData();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleClickCreateNewTableDefinition() {
        this.setState({ createNewTableDefinition: true });
    }

    handleUpgradeClick(event) {
        console.log('Upgrade now');
        event.preventDefault();
        // https://developer.monday.com/apps/docs/mondayexecute
        monday.execute('openPlanSelection', { isInPlanSelection: false });
    }

    render() {
        if (this.state.createNewTableDefinition) {
            return (
                <TableDefinitionEdit columns={this.props.columns} subItemsColumns={this.props.subItemsColumns} isReportGenerator={this.props.isReportGenerator} onClose={this.handleTableEditClose}></TableDefinitionEdit>
            );
        }
        let contents = this.renderNewTable(this.state.tableDefinitions);
        const url = this.props.isReportGenerator ? "https://www.pdfreportgenerator.com/help/template/tables/" : "https://www.docexport.com/help/template/tables/";

        return (
            <div>
                <h3 id="tableLabel">Tables</h3>
                <Button onClick={this.handleClickCreateNewTableDefinition}>
                    Create new table
                </Button>
                <p>                    
                </p>
                {contents}
                <p><Link href={url} text="Learn how tables work" /></p>
            </div>
        );
    }

    async populateTableDefinitionData() {
        var timeZoneOffset = this.mondayContext.user.timeZoneOffset;
        if (timeZoneOffset === undefined)
            timeZoneOffset = 0;
        const response = await fetch('tabledefinition/list?BoardId=' + this.mondayContext.boardId +
            '&TimeZoneOffset=' + timeZoneOffset +
            '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ tableDefinitions: data, loading: false });
    }
}
