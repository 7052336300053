export function convertMondayFilterToGraphQLFilter(mondayFilter) {
    let resultString = '[';

    mondayFilter.forEach((item, index) => {
        if (index > 0) resultString += ','; // Add comma between objects
        resultString += '{';
        Object.keys(item).forEach((key, keyIndex) => {
            if (keyIndex > 0) resultString += ','; // Add comma between key-value pairs
            const value = item[key];
            // Format the key without quotes
            resultString += `${key}:`;
            // Format the value based on its type
            if (Array.isArray(value)) {
                // For arrays, stringify manually, adding quotes if elements are not numbers
                const arrayString = value.map(v => isNaN(Number(v)) ? `"${v}"` : v).join(',');
                resultString += `[${arrayString}]`;
            } else if (typeof value === 'string') {
                // For strings, check if it's operator or not
                resultString += key === 'operator' ? value : `"${value}"`;
            } else {
                // For other types (number, boolean), add directly
                resultString += value;
            }
        });
        resultString += '}';
    });

    resultString += ']';
    console.log(resultString);
    return resultString;
}
