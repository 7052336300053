import React, { Component, createRef } from 'react';
import { Button, Flex, Link } from "monday-ui-react-core";
import JoditEditor from 'jodit-react';
import { PlaceholderListMonday } from './PlaceholderListMonday';

export class TemplateElementTextEdit extends Component {
    static displayName = TemplateElementTextEdit.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            /*id: '',*/
            content: this.props.content ?? '',
        };

        this.editorConfig = {
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            height: 400,            
            buttons: ['paragraph', 'bold', 'underline', 'italic', '|', //'ul', 'ol',
                // See https://github.com/xdan/jodit/blob/main/src/plugins/ordered-list/ordered-list.ts#L24
                {
                    name: 'ul_new',
                    icon: 'ul', // Icon class from 'jodit-icons
                    tooltip: 'Insert Unordered List',
                    command: 'insertUnorderedList',
                },
                {
                    name: 'ol_new',
                    icon: 'ol', // Icon class from 'jodit-icons
                    tooltip: 'Insert Ordered List',
                    command: 'insertOrderedList',
                },
                '|', 'image', 'table', 'link', '|', 'align', 'undo', 'redo'],             
            addNewLine: false
        };

        this.mondayContext = window.mondayContext;
        this.localStorageStateIdentifier = "HtmlEditor" + this.mondayContext.boardId + "_" + this.props.editTextId;
        this.towerAppInfos = window.towerAppInfos;
        this.editorRef = createRef();
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleEditorContentChange = this.handleEditorContentChange.bind(this);
        this.handlePlaceholderClick = this.handlePlaceholderClick.bind(this);        
    }

    componentDidMount() {
        try {
            const savedState = localStorage.getItem(this.localStorageStateIdentifier);
            if (savedState) {
                console.log('Using saved state ' + this.localStorageStateIdentifier + ": " + savedState);
                var savedStateParsed = JSON.parse(savedState);
                this.setState(savedStateParsed);
            }
            else
                this.setState({ ...this.state, loading: false });
        } catch (error) {
            console.error("Failed to load editor state from local storage:", error);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading)
            return;
        //if (this.state.isSaving)
        //    return;
        // Save state to local storage
        if (this.state !== prevState) {
            //if (this.state.isSaved)
            //    localStorage.removeItem(this.localStorageStateIdentifier);
            //else
            localStorage.setItem(this.localStorageStateIdentifier, JSON.stringify(this.state));
        }
    }

    handleCancelClick() {
        localStorage.removeItem(this.localStorageStateIdentifier);
        this.props.onClose();
    }

    async handleClickSave() {
        localStorage.removeItem(this.localStorageStateIdentifier);
        this.props.onClose(this.state.content);
    }

    handleEditorContentChange(content) {
        this.setState({ content: content });
    }

    handlePlaceholderClick(placeholder) {
        if (this.editorRef.current && this.editorRef.current.component) {
            this.editorRef.current.component.s.insertHTML(placeholder);
        } else {
            console.error('Editor reference is not available');
        }
    }

    render() {
        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-template-element-text-editor">
                    <JoditEditor
                        ref={this.editorRef}
                        value={this.state.content}
                        config={this.editorConfig}
                        onBlur={this.handleEditorContentChange}
                    />
                    <p></p>
                    <Button onClick={this.handleClickSave}>Save</Button>
                    <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.handleCancelClick}>Cancel</Button>
                    <br /><br />
                    <p>Tip: Click on a placeholder on the right side to add it to the editor. <Link href="https://www.pdfreportgenerator.com/help/placeholders/" text="Help Center" /></p>
                </div>
                <div className="app-spirit-placeholder-list">
                    <PlaceholderListMonday
                        columns={this.props.columns}
                        subItemsColumns={this.props.subItemsColumns}
                        dataSource={this.props.dataSource}
                        isReportGenerator={this.props.isReportGenerator}
                        onPlaceholderClick={this.handlePlaceholderClick} />
                </div>
            </Flex>
        );
    }
}